import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import Features from "../components/features"
import LinkList from "../components/linkList"
import PageIntro from "../components/pageIntro"

class ParentTemplate extends Component {
  pageContent(content) {
    let entryClasses = content === "" ? "entry" : "entry entry--space-top"
    return (
      <div className="row center-bp1">
        <div className="col-bp1-12 col-bp4-7">
          <div className={entryClasses}>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const currentPage = this.props.data.allWpPage.edges[0].node,
      introTitle =
        currentPage.introduction != null
          ? currentPage.introduction.introTitle
          : "",
      introDesc =
        currentPage.introduction !== null ? currentPage.introduction.intro : "",
      seoTitle = currentPage.title,
      seoDesc = ""
    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />
          <PageTitle pageTitle={currentPage.title} />
          <PageIntro introTitle={introTitle} introDesc={introDesc} />

          <section className="section sectionWhite">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12">
                  <h3 className="section__title">
                    <span>In this section</span>
                  </h3>
                  <LinkList
                    parentId={currentPage.parentId}
                    pageSlug={currentPage.slug}
                  />
                </div>
              </div>
              {this.pageContent(currentPage.content)}
            </div>
          </section>

          <section className="section sectionWhite section--close-btm">
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ParentTemplate

export const pageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          parentId
          databaseId
          title
          content
          slug
          introduction {
            introTitle
            intro
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
